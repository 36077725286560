import Profile from "./components/Profile";
import Experience from "./components/Experience";
import {Footer} from "./components/Footer";
import React from "react";

const App = () => {
    return (<div>
        <Profile/>
        <hr/>
        <Experience/>
        <hr/>
        <Footer/>
    </div>)
}
export default App;