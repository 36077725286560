import './Profile.css';
import {AiFillGithub, AiFillLinkedin, AiFillMail} from "react-icons/ai";

const Profile = () => {
    return (
        <div className={'section'}>
            <div className="bio">
                <p className={'no-margin small-text'}>G'day</p>
                <p className={'no-margin name-text'}>
                    I'm <span className={'highlight-text'}>Fred Gerner</span>
                </p>
                <p className={'no-margin small-text'}>Quality Analyst <span
                    className={'highlight-text'}>and</span> Full-stack Developer</p>
                <div className="icons">
                    <a href="mailto:fred@gerner.au">
                        <AiFillMail color={'orange'} size={30}/>
                    </a>
                    <a href="https://www.linkedin.com/in/fredrikgerner/">
                        <AiFillLinkedin color={'orange'} size={30}/>
                    </a>
                    <a href="https://github.com/fgerner">
                        <AiFillGithub color={'orange'} size={30}/>
                    </a>
                </div>
            </div>
                <div className="photo">
                    <img src="/images/Photo.jpg" alt="fred headshot" className={'profile-image'} />
                </div>
        </div>
    )
}
export default Profile;
