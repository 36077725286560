import './Experience.css';


const Experience = () => {
    const items = [{
        id: 1,
        title: "QA Engineer",
        company: "Australian Broadcasting Corporation",
        date: "Oct 2023 - Current",
        description: "Backend QA engineering",
        points: [
            "Golang",
            "Python",
            "Elastic search",
            "GraphQL",
            "Docker",
        ],
        image: "/images/download.png"
    },{
        id: 2,
        title: "Developer / CEO",
        company: "Highlands Digital",
        date: "Jan 2023 - Current",
        description: "Web Development",
        points: [
            "Full stack web development",
            "JavaScript, CSS, HTML, Java/Spring, Python",
            "Web3 / Blockchain"
        ],
        image: "/images/pngegg.png"
    }, {
        id: 3,
        title: "Senior Quality Analyst",
        company: "Qantas, Vervio Digital",
        date: "Feb 2022 - Dec 2022",
        description: "CMS platform upgrade",
        points: [
            "AEM",
            "Manual Regression testing",
            "Cypress automation framework",
            "Performance testing with K6"
        ],
        image: "/images/vervio.png"
    }, {
        id: 4,
        title: "Senior Quality Engineer",
        company: "Momenton",
        date: "May 2018 - Jan 2022",
        description: "Consulting at NAB and ANZ",
        points: [
            "Back and Front end testing",
            "Manual and automated Rest assured API testing with Postman",
            "Puppeteer automation framework"
        ],
        image: "/images/momenton.png"
    }, {
        id: 5,
        title: "Senior Quality Assurance Analyst",
        company: "myob",
        date: "May 2015 - May 2018",
        description: "Testing of the Account Right Live product",
        points: [
            "Back and Front end testing",
            "Manual and automated Rest assured API testing with Postman",
            "WatiN automation framework in C#.Net"
        ],
        image: "/images/myob.png"
    }, {
        id: 6,
        title: "Senior Test Analyst",
        company: "Planit Software Testing",
        date: "Jun 2013 - May 2015",
        description: "Test-consultant",
        points: [
            "Mobile app testing",
            "Selenium automation framework in C#.Net"
        ],
        image: "/images/planit.png"
    },
    ];

    return (
        <div className={'experience'}>
            <h1 className={'header'}>Work Experience</h1>
            <div className="work-items">
                {items.map((item) => (
                        <div className={"item"} key={item.id}>
                            <img src={item.image} alt="logo" className={'logo'}/>
                            <div className="item-content">
                                <div className="item-header">
                                    <h2 className={'no-margin'}>{item.title}</h2>
                                    <h4 className={'no-margin'}>{item.company}</h4>
                                    <p className={"no-margin text-muted"}>{item.date}</p>
                                </div>
                                <p className="item-description no-margin">{item.description}</p>
                                <ul className={'points'}>
                                    {item.points.map((point) => (
                                        <li key={Math.random()}>{point}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )
                )}
            </div>
        </div>
    )
}

export default Experience;
